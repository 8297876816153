.service-concept-section {
    text-align: center;
    padding: 50px 100px;
    background-color: var(--color-bg-light)
}

.service-concept-section h2 {
    font-size: 40px;
    color: var(--color-primary);
    margin-bottom: 20px;
}

.intro-text {
    font-size: 1.2em;
    color: var(--color-text-grey);
    max-width: 800px;
    margin: 0 auto 40px;
}

.service-cards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
}

.service-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    max-width: 300px;
    text-align: left;
}

.service-icon {
    font-size: 2.5em;
    color: var(--color-secondary);
    margin-bottom: 10px;
}

.service-card h3 {
    font-size: 1.5em;
    color: var(--color-primary);
    margin-bottom: 10px;
}

.service-card h4 {
    font-size: 1.2em;
    color: var(--color-primary);
    margin-bottom: 20px;
}

.service-card ul {
    list-style-type: none;
    padding: 0;
    color: var(--color-text-grey);
}

.service-card ul li {
    margin-bottom: 10px;
    padding-left: 20px;
    position: relative;
}

.service-card ul li::before {
    content: '•';
    color: var(--color-secondary-dark);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

@media (max-width: 1200px) {
    .service-concept-section {
        padding: 40px 80px; /* Reduce padding for medium screens */
    }

    .service-concept-section h2 {
        font-size: 36px; /* Slightly reduce the font size */
    }

    .intro-text {
        font-size: 1.1em; /* Slightly reduce font size for intro text */
        max-width: 700px; /* Adjust max-width */
    }

    .service-card h3 {
        font-size: 1.4em; /* Adjust font size for smaller headers */
    }

    .service-card h4 {
        font-size: 1.1em; /* Adjust font size for subheaders */
    }

    .service-card {
        max-width: 250px; /* Adjust card width */
    }
}

@media (max-width: 768px) {
    .service-concept-section {
        padding: 30px 50px; /* Further reduce padding for tablet-sized screens */
    }

    .service-concept-section h2 {
        font-size: 32px; /* Adjust font size for smaller screens */
    }

    .intro-text {
        font-size: 1em; /* Further reduce font size */
        max-width: 600px; /* Adjust max-width */
    }

    .service-cards {
        flex-direction: column; /* Stack service cards vertically */
        align-items: center; /* Center align for better aesthetics */
    }

    .service-card {
        max-width: 90%; /* Use most of the screen width */
    }

    .service-card h3 {
        font-size: 1.3em; /* Further adjust font size */
    }

    .service-card h4 {
        font-size: 1em; /* Further adjust font size for subheaders */
    }
}

@media (max-width: 480px) {
    .service-concept-section {
        padding: 20px; /* Minimal padding for mobile screens */
    }

    .service-concept-section h2 {
        font-size: 28px; /* Further reduce font size for headers */
    }

    .intro-text {
        font-size: 0.9em; /* Smallest font size for intro text */
        max-width: 100%; /* Full width on mobile */
    }

    .service-card {
        max-width: 100%; /* Full width for service cards */
    }

    .service-card h3 {
        font-size: 1.2em; /* Adjust font size for headers */
    }

    .service-card h4 {
        font-size: 0.9em; /* Adjust font size for subheaders */
    }
}
