.product-pictures-container {
    padding: 30px;
}

.product-main-section {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.product-cat-series {
    align-items: center;
    margin-bottom: 5px;
}

.product-cat-series svg {
    font-size: 12px;
    margin-right: 2px;
    margin-left: 2px;
    vertical-align: middle;
}

.product-cat-series h3 {
    color: var(--color-tertiary);
    font-size: 15px;
}

.product-info-and-brochure {
    flex: 1;
}

.product-info-and-brochure h1 {
    font-size: 32px;
    font-weight: 900;
    margin-bottom: 20px;
    margin-right: 50px;
    color: var(--color-primary);
}

.product-info-and-brochure p {
    color: var(--color-text-grey);
    font-size: 14px;
    margin-bottom: 20px;
    margin-right: 50px;
}

.brochure-link {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    color: var(--color-quaternary);
    text-decoration: none;
    margin-bottom: 40px;
}

.brochure-link:hover {
    text-decoration: underline;
}

.brochure-link svg {
    margin-right: 5px;
    vertical-align: middle;
}

.contact-us-buttons {
    display: flex;
    flex-direction: row; /* Ensure buttons are in a row */
    justify-content: flex-start; /* Align buttons to the left */
    gap: 20px;
}

.contact-us-btn {
    padding: 10px 20px;
    background-color: var(--color-secondary-dark);
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s;
    text-decoration: none;
}

.contact-us-btn:hover {
    background-color: var(--color-secondary);
}


.product-images {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.main-image {
    position: relative;
}

.main-image img {
    width: 400px;
    height: 400px;
    object-fit: cover;
}

.comp-main-image img{
    width: 600px;
    height: 400px;
    object-fit: cover;
}

.thumbnails {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}

.thumbnails img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    margin-bottom: 5px;
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 2px;
}

.thumbnails img.selected {
    border: 1px solid var(--color-primary);
}

.thumbnails-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.3);
    color: white;
    padding: 5px;
    border-radius: 50%;
    user-select: none;
    z-index: 10; /* Ensure arrows are above other elements */
    width: 40px;
    height: 40px;
}

.thumbnails-arrow:hover{
    background: rgba(0, 0, 0, 0.5);
}

.thumbnails-arrow-left {
    left: 10px;
}

.thumbnails-arrow-right {
    right: 10px;
}

.product-specs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 20px;
}

.spec-item {
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
}

.spec-item h3 {
    color: var(--color-primary);
    font-size: 18px;
    margin-bottom: 5px;
}

.spec-item p {
    color: var(--color-text-grey);
    font-size: 14px;
}

.spec-item img {
    width: 50px;
    height: auto;
    margin-bottom: 10px;
}

@media (max-width: 1600px) {

    .comp-main-image img{
        width: 500px;
        height: 350px;
    }
}

@media (max-width: 1400px) {

    .comp-main-image img{
        width: 400px;
        height: 320px;
    }
}

@media (max-width: 1200px) {
    .product-pictures-container {
        padding: 20px;
    }

    .product-main-section {
        flex-direction: column;
    }

    .product-info-and-brochure {
        margin-bottom: 20px;
    }

    .product-images {
        margin-top: 20px;
        margin-bottom: 20px;
        justify-content: left;
    }

    .thumbnails {
        margin-bottom: 10px;
    }

    .thumbnails img.selected {
        border: 2px solid var(--color-primary);
    }
}

@media (max-width: 768px) {
    .product-pictures-container {
        padding: 15px;
    }

    .product-info-and-brochure h1 {
        font-size: 24px;
    }

    .product-info-and-brochure p {
        font-size: 12px;
        margin-right: 0;
    }

    .brochure-link {
        font-size: 12px;
        margin-bottom: 20px;
    }

    .contact-us-btn {
        padding: 8px 16px;
        font-size: 12px;
    }

    .main-image img {
        width: 250px;
        height: 250px;
    }

    .comp-main-image img{
        width: 350px;
        height: 250px;
    }

    .thumbnails img {
        width: 40px;
        height: 40px;
    }

    .spec-item h3 {
        font-size: 16px;
    }

    .spec-item p {
        font-size: 12px;
    }

    .spec-item img {
        width: 40px;
    }
}

@media (max-width: 480px) {
    .product-pictures-container {
        padding: 10px;
    }

    .product-info-and-brochure h1 {
        font-size: 20px;
    }

    .product-info-and-brochure p {
        font-size: 10px;
    }

    .brochure-link {
        font-size: 10px;
    }

    .contact-us-btn {
        padding: 6px 12px;
        font-size: 10px;
    }

    .main-image img {
        width: 200px;
        height: 200px;
    }

    .comp-main-image img{
        width: 230px;
        height: 200px;
    }

    .thumbnails img {
        width: 30px;
        height: 30px;
    }

    .spec-item h3 {
        font-size: 14px;
    }

    .spec-item p {
        font-size: 10px;
    }

    .spec-item img {
        width: 30px;
    }
}

@media (max-width: 360px) {
    .product-specs {
        display: grid;
        gap: 10px;
        margin-top: 10px;
    }
    
    .spec-item {
        padding: 5px;
    }
    
    .spec-item h3 {
        font-size: 10px;
        margin-bottom: 2px;
    }
    
    .spec-item p {
        font-size: 8px;
    }
    
    .spec-item img {
        margin-bottom: 5px;
    }
}
