.header-for-baler-products {
    width: 100%;
    height: 400px;
    overflow: hidden;
}

.header-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.header-text {
    padding: 50px 300px;
    color: var(--color-primary);
    background-color: white;
}

.header-text h2 {
    margin-bottom: 20px;
    font-weight: 800;
    font-size: 22px;
}

.header-text p {
    margin-bottom: 20px;
    color: var(--color-primary-lighter);
}

@media (max-width: 1200px) {
    .header-text {
        padding: 40px 100px; /* Reduce padding for medium screens */
    }

    .header-text h2 {
        font-size: 1.3em; /* Adjust font size for headers */
    }

    .header-text p {
        font-size: 0.9em; /* Adjust font size for paragraphs */
    }
}

@media (max-width: 768px) {
    .header-text {
        padding: 30px 50px; /* Reduce padding for tablet-sized screens */
    }
    
    .header-text h2 {
        font-size: 1.1em; /* Adjust font size for headers */
    }

    .header-text p {
        font-size: 0.8em; /* Adjust font size for paragraphs */
    }
}

@media (max-width: 480px) {
    .header-for-baler-products {
        height: 250px; /* Reduce image height on small screens */
    }

    .header-text {
        padding: 20px; /* Minimal padding for mobile devices */
    }

    .header-text h2 {
        font-size: 1.2em; /* Smaller font size for headers */
    }

    .header-text p {
        font-size: 0.9em; /* Smaller font size for paragraphs */
    }
}
