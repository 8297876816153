.not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--color-bg);
    text-align: center;
    color: #333;
    padding: 20px;
}

.not-found-container h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: var(--color-secondary);
}

.not-found-container p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #666;
}

.not-found-container a {
    text-decoration: none;
    color: var(--color-quaternary);
    font-weight: bold;
}

.not-found-container a:hover {
    text-decoration: underline;
}
