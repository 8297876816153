.CTA-homepage {
    background-color: var(--color-bg-light);
    color: var(--color-primary-lighter);
    padding: 50px 0;
    text-align: center;
}

.CTA-homepage h1 {
    font-size: 30px;
    font-weight: 800;
    color: var(--color-primary);
    margin-bottom: 30px;
}

.CTA-homepage h2 {
    font-size: 20px;
    font-weight: 300;
    color: var(--color-text);
    margin-bottom: 5px;
}

.CTA-button-homepage {
    margin-top: 30px;
    display: inline-block;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.CTA-button-homepage:hover {
    background-color: var(--color-primary);
    color: var(--color-bg-light);
}

@media (max-width: 1200px) {
    .CTA-homepage {
      padding: 40px 0; /* Adjust padding for medium screens */
    }
  
    .CTA-homepage h1 {
      font-size: 26px; /* Adjust font size */
      margin-bottom: 25px; /* Adjust margin */
    }
  
    .CTA-homepage h2 {
      font-size: 18px; /* Adjust font size */
      margin-bottom: 4px; /* Adjust margin */
    }
  
    .CTA-button-homepage {
      padding: 8px 18px; /* Adjust padding */
      font-size: 16px; /* Adjust font size */
    }
  }
  
  @media (max-width: 768px) {
    .CTA-homepage {
      padding: 30px 0; /* Adjust padding for small screens */
    }
  
    .CTA-homepage h1 {
      font-size: 22px; /* Adjust font size */
      margin-bottom: 20px; /* Adjust margin */
    }
  
    .CTA-homepage h2 {
      font-size: 16px; /* Adjust font size */
      margin-bottom: 3px; /* Adjust margin */
    }
  
    .CTA-button-homepage {
      padding: 7px 16px; /* Adjust padding */
      font-size: 14px; /* Adjust font size */
    }
  }
  
  @media (max-width: 480px) {
    .CTA-homepage {
      padding: 20px 0; /* Adjust padding for very small screens */
    }
  
    .CTA-homepage h1 {
      font-size: 20px; /* Adjust font size */
      margin-bottom: 15px; /* Adjust margin */
    }
  
    .CTA-homepage h2 {
      font-size: 14px; /* Adjust font size */
      margin-bottom: 2px; /* Adjust margin */
    }
  
    .CTA-button-homepage {
      padding: 6px 14px; /* Adjust padding */
      font-size: 12px; /* Adjust font size */
    }
  }
  