@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
    --font-family: 'Manrope', sans-serif;

    --color-primary: #2D383F;
    --color-secondary: #6CBC5C;
    --color-tertiary: #304266;
    --color-quaternary: #da6d2d;

    --color-bg: #e9e9e9;
    --color-secondary-dark: #63ad54;
    --color-primary-light: #c1d2dd;
    --color-primary-lighter: #475964;
    --color-text-grey: #7b7b7b;
    --color-bg-light: #F5F5F5;
}