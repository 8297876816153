.brain-intro-text-container {
    padding: 80px;
}

.brain-intro-text-container h1 {
    color: var(--color-primary);
    font-size: 2em;
    font-weight: 1000;
    margin-bottom: 20px;
}

.brain-intro-text-container h2 {
    color: var(--color-primary);
    font-size: 1.2em;
    font-weight: 1000;
    margin-top: 25px;
    margin-bottom: 10px;
}

.brain-intro-text-container h3 {
    color: var(--color-primary);
    font-weight: 700;
    font-size: 1.2em;
    line-height: 1.5;
    margin-bottom: 15px;
}

.brain-intro-text-container p {
    color: var(--color-primary-lighter);
    font-size: 0.9em;
    line-height: 1.6;
    margin-bottom: 10px;
}

@media (max-width: 1200px) {
    .brain-intro-text-container {
        padding: 60px; /* Reduce padding for medium screens */
    }

    .brain-intro-text-container h1 {
        font-size: 1.8em; /* Slightly reduce the font size */
    }

    .brain-intro-text-container h2 {
        font-size: 1.1em; /* Reduce font size for headers */
    }

    .brain-intro-text-container h3 {
        font-size: 1.1em; /* Adjust font size */
    }

    .brain-intro-text-container p {
        font-size: 0.85em; /* Adjust font size for paragraphs */
    }
}

@media (max-width: 768px) {
    .brain-intro-text-container {
        padding: 40px; /* Further reduce padding for tablet-sized screens */
    }

    .brain-intro-text-container h1 {
        font-size: 1.6em; /* Adjust font size for smaller screens */
    }

    .brain-intro-text-container h2 {
        font-size: 1em; /* Smaller font size for headers */
    }

    .brain-intro-text-container h3 {
        font-size: 1em; /* Smaller font size */
    }

    .brain-intro-text-container p {
        font-size: 0.8em; /* Further reduce paragraph font size */
    }
}

@media (max-width: 480px) {
    .brain-intro-text-container {
        padding: 20px; /* Minimal padding for mobile screens */
    }

    .brain-intro-text-container h1 {
        font-size: 1.4em; /* Further adjust font size for mobile */
    }

    .brain-intro-text-container h2 {
        font-size: 0.9em; /* Smallest font size for headers */
    }

    .brain-intro-text-container h3 {
        font-size: 0.9em; /* Smallest font size for subheaders */
    }

    .brain-intro-text-container p {
        font-size: 0.75em; /* Smallest font size for paragraphs */
    }
}
