/* newsdisplay.css */

.news-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three cards per row */
    gap: 50px; /* Increased gap */
    padding: 10px 40px 50px 40px;
    background-color: var(--color-bg-light);
}

.news-card {
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s;
    display: flex;
    flex-direction: column;
}

.news-card img {
    width: 100%;
    height: 200px; /* Increased height for the image */
    object-fit: cover;
}

.news-card-content {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    overflow: hidden; /* Ensure content fits within the card */
}

.news-card-title {
    font-size: 18px; /* Adjusted font size */
    color: var(--color-primary);
    margin-bottom: 5px;
}

.news-card-date {
    font-size: 12px; /* Adjusted font size */
    color: var(--color-text-grey);
    margin-bottom: 5px;
}

.news-card-description {
    font-size: 14px;
    color: var(--color-primary-lighter);
    margin-bottom: 10px;
    flex-grow: 1; /* Allow description to take available space */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Add ellipsis if text is too long */
    display: -webkit-box; /* Support for multiline ellipsis */
    -webkit-line-clamp: 3; /* Limit the number of lines */
    -webkit-box-orient: vertical; /* Vertical orientation for the box */
}

.news-card-read-more {
    color: var(--color-secondary-dark);
    font-size: 14px;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
}

.news-card svg {
    margin-left: 5px;
    font-size: 12px;
    transition: margin-left 0.2s ease;
}

.news-card-read-more:hover {
    text-decoration: underline;
    color: var(--color-secondary);
}

.news-card-read-more:hover svg {
    margin-left: 15px;
}

@media (max-width: 1200px) {
    .news-container {
        grid-template-columns: repeat(2, 1fr); /* Two cards per row */
        gap: 30px; /* Adjust gap for medium screens */
    }

    .news-card img {
        height: 180px; /* Adjust image height */
    }

    .news-card-title {
        font-size: 16px; /* Reduce font size for titles */
    }

    .news-card-description {
        -webkit-line-clamp: 2; /* Reduce the number of lines */
    }

    .news-card-read-more {
        font-size: 13px; /* Adjust font size for the read more link */
    }
}

@media (max-width: 768px) {
    .news-container {
        grid-template-columns: 1fr; /* One card per row */
        gap: 20px; /* Further reduce gap for tablet-sized screens */
    }

    .news-card {
        flex-direction: column;
    }

    .news-card img {
        height: 160px; /* Further adjust image height */
    }

    .news-card-content {
        padding: 15px;
    }

    .news-card-title {
        font-size: 14px; /* Smaller font size for titles */
    }

    .news-card-date {
        font-size: 11px; /* Smaller font size for date */
    }

    .news-card-description {
        font-size: 13px; /* Smaller font size for description */
        -webkit-line-clamp: 2; /* Limit to two lines */
    }

    .news-card-read-more {
        font-size: 12px; /* Smaller font size for the read more link */
    }
}

@media (max-width: 480px) {
    .news-container {
        padding: 20px; /* Minimal padding for mobile screens */
    }

    .news-card img {
        height: 140px; /* Minimum image height for mobile */
    }

    .news-card-title {
        font-size: 12px; /* Further reduce font size */
    }

    .news-card-date {
        font-size: 10px; /* Further reduce font size */
    }

    .news-card-description {
        font-size: 11px; /* Minimum font size for description */
        -webkit-line-clamp: 1; /* Limit to one line */
    }

    .news-card-read-more {
        font-size: 11px; /* Minimum font size for the read more link */
    }
}
