.brain-youtube-container {
    display: flex;
    align-items: center;
    padding: 50px 150px;
    background: var(--color-primary);
    color: var(--color-bg-light);
    gap: 70px;
}

.brain-youtube-embed-description {
    flex: 1;
}

.brain-small-letters {
    font-size: 11px;
    font-weight: 700;
    margin-bottom: 10px;
    color: var(--color-primary-light);
}

.brain-youtube-embed-title {
    margin-bottom: 10px;
}

.brain-youtube-description {
    color: var(--color-primary-light);
    font-size: 13px;
}

@media (max-width: 1800px) {
    .brain-youtube-container {
        padding: 50px 50px;
        gap: 60px;
    }
}

@media (max-width: 1200px) {
    .brain-youtube-container {
        padding: 40px 100px; /* Reduce padding for medium screens */
        gap: 50px; /* Adjust gap between elements */
    }

    .brain-small-letters {
        font-size: 10px; /* Adjust font size for smaller screens */
    }

    .brain-youtube-description {
        font-size: 12px; /* Adjust font size for description */
    }

    .youtube-embed-wrapper iframe {
        width: 100%; /* Ensure iframe takes full width */
        height: 300px; /* Adjust height to fit better */
    }
}

@media (max-width: 768px) {
    .brain-youtube-container {
        flex-direction: column; /* Stack elements vertically */
        padding: 30px 50px; /* Further reduce padding for tablet-sized screens */
        gap: 30px; /* Reduce gap for a more compact layout */
    }

    .brain-youtube-embed-description {
        text-align: center; /* Center text for better alignment on smaller screens */
    }

    .brain-youtube-embed-title {
        font-size: 18px; /* Adjust title font size */
    }

    .brain-youtube-description {
        font-size: 11px; /* Further reduce font size for description */
    }

    .youtube-embed-wrapper iframe {
        width: 400px;
    }
}

@media (max-width: 480px) {
    .brain-youtube-container {
        padding: 20px; /* Minimal padding for mobile screens */
    }

    .brain-small-letters {
        font-size: 9px; /* Smallest font size for small text */
    }

    .brain-youtube-embed-title {
        font-size: 16px; /* Smaller font size for titles */
        margin-bottom: 8px; /* Adjust margin for smaller spacing */
    }

    .brain-youtube-description {
        font-size: 10px; /* Smallest font size for description */
    }

    .youtube-embed-wrapper iframe {
        width: 100%; /* Full width for iframe */
        height: 200px; /* Minimal height for mobile */
    }
}
