.about-presentation {
    padding: 10px 50px;
    color: var(--color-primary);
    background-color: var(--color-bg-light);
}

.about-presentation h1 {
    font-size: 32px;
    font-weight: 800;
    margin-bottom: 20px;
}

.about-presentation p {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
    color: var(--color-text-grey);
}

.bramidan-presto-logo {
    width: 50%;
    margin-bottom: 40px;
    margin-top: 40px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 1200px) {
    .about-presentation {
      padding: 10px 50px; /* Adjust padding for medium screens */
    }
  
    .about-presentation h1 {
      font-size: 28px; /* Adjust font size */
      margin-bottom: 18px; /* Adjust margin */
    }
  
    .about-presentation p {
      font-size: 16px; /* Adjust font size */
      margin-bottom: 18px; /* Adjust margin */
    }
  
    .bramidan-presto-logo {
      width: 60%; /* Adjust width for medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .about-presentation {
      padding: 10px 25px; /* Adjust padding for small screens */
    }
  
    .about-presentation h1 {
      font-size: 24px; /* Adjust font size */
      margin-bottom: 16px; /* Adjust margin */
    }
  
    .about-presentation p {
      font-size: 14px; /* Adjust font size */
      margin-bottom: 16px; /* Adjust margin */
    }
  
    .bramidan-presto-logo {
      width: 40%; /* Adjust width for small screens */
      margin-bottom: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .about-presentation {
      padding: 10px; /* Adjust padding for very small screens */
    }
  
    .about-presentation h1 {
      font-size: 18px; /* Adjust font size */
      margin-bottom: 14px; /* Adjust margin */
    }
  
    .about-presentation p {
      font-size: 11px; /* Adjust font size */
      margin-bottom: 14px; /* Adjust margin */
    }
  
    .bramidan-presto-logo {
      width: 60%; /* Adjust width for very small screens */
    }
  }
  