.product-models-container {
    padding: 50px 150px;
}

.product-models-container h1 {
    font-size: 1.6em;
    font-weight: 900;
    color: var(--color-primary);
    margin-bottom: 20px;
}

.product-models-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.product-model-item {
    border-radius: 8px;
    padding: 15px;
    box-sizing: border-box;
}

.model-picture-container {
    height: 200px;
    width: 300px;
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    margin-bottom: 10px;
}

.product-model-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.product-model-item h2 {
    color: var(--color-primary);
    font-size: 1.2em;
    margin-bottom: 10px;
}

.product-model-item p {
    font-size: 0.9em;
    color: var(--color-text-grey);
    line-height: 1.6;
    margin-bottom: 10px;
}

.product-model-item ul {
    list-style-type: none;
    font-size: 0.9em;
    padding: 0;
    margin-bottom: 20px;
}

.product-model-item ul li {
    color: var(--color-text-grey);
    line-height: 1.8;
    margin-bottom: 5px;
}

.product-model-item ul li svg {
    margin-right: 5px;
}

.product-model-item a {
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    margin-top: 10px;
    color: var(--color-secondary);
    text-decoration: none;
}

.product-model-item a svg{
    margin-right: 5px;
}

.product-model-item a:hover{
    text-decoration: underline;
}


@media (max-width: 1600px) {
    .product-models-container {
        padding: 40px 100px;
    }
}

@media (max-width: 1500px) {
    
    .product-models-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .product-models-container {
        padding: 30px 50px;
    }
    
    .product-model-item {
        padding: 10px;
    }

    .model-picture-container {
        height: 150px;
        width: 250px;
    }

}

@media (max-width: 620px) {
    .product-models-container {
        padding: 10px 40px;
    }
    
    .product-model-item {
        padding: 5px;
    }

    .model-picture-container {
        height: 200px;
        width: 300px;
    }

    .product-models-grid {
        grid-template-columns: 1fr;
    }

}

@media (max-width: 480px) {
    .product-models-container {
        padding: 20px;
    }

    .product-model-item h2 {
        font-size: 1em;
    }
    
    .product-model-item p, 
    .product-model-item ul,
    .product-model-item ul li,
    .product-model-item a {
        font-size: 0.8em;
    }

    .model-picture-container {
        height: 150px;
        width: 250px;
    }

    .product-model-item a {
        margin-top: 0px;
    }

    .product-model-item ul {
        margin-bottom: 10px;
    }
    
}