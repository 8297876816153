.product-display-container {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin: 0 auto;
    padding: 20px 10px;
    color: var(--color-text-grey);
}

.product-display-container h1 {
    color: var(--color-primary);
    font-size: 28px;
    font-weight: 800;
    margin-top: 20px;
}

.product-list {
    flex: 4;
    display: flex;
    flex-direction: column;
}

.product-display-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px 50px;
    margin: 10px 0; /* Adjust vertical margin for spacing between items */
    width: 100%; /* Full width */
    transition: transform 0.3s ease-in-out; /* Smooth transition */
    background-color: white;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
}

.display-image-thumbnail-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    width: 350px;
    margin-right: 40px;
    margin-top: 30px;
    overflow: hidden;
}

.display-image-thumbnail {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}

.product-display-item:hover {
    cursor: pointer;
    transform: scale(1.01); /* Adjust the scale factor to control the size increase */
    text-decoration: none;
    color: inherit;
}

.product-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
}

.specific-information {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    align-items: baseline;
}

.product-details h2 {
    font-size: 24px;
    font-weight: 1000;
    text-align: center;
    margin-bottom: 20px;
    color: var(--color-primary);
}

.product-details h3 {
    font-size: 20px;
    margin-bottom: 5px;
    color: var(--color-primary-lighter);
}

.product-details p {
    margin-bottom: 15px;
}

.icon-and-text {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px 0px;
}


.icon-and-text .icon {
    width: 35px; /* Adjust size as needed */
    height: 35px;;
    margin-right: 0;
    margin-bottom: 5px; /* Space between icon and text */
}

.separator {
    width: 100%;
    border: none;
    border-top: 1px solid #ccc;
    margin: 10px 0;
}

.descriptive-product-text {
    font-size: 15px;
    text-align: start;
}

.h2-displayed-below-480 {
    display: none;
}

@media (max-width: 1400px) {

    .product-display-container h1 {
        font-size: 20px;
    }
    .product-display-container {
        width: 95%; 
    }
    .product-details h2 {
        font-size: 18px;
    }
    .icon-and-text .icon {
        width: 22px;
        height: 22px;
    }
    .icon-and-text h3 {
        font-size: 16px;
    }
    .icon-and-text p {
        font-size: 12px;
    }
    .descriptive-product-text {
        font-size: 12px;
    }
    .product-display-item {
        padding: 10px;
        height: 300px;
    }

    .display-image-thumbnail-container {
        height: 200px;
        width: 300px;
        margin-right: 20px;
        margin-top: 20px;
    }

}

@media (max-width: 700px) {
    .specific-information {
        grid-template-columns: repeat(2, 1fr);
        gap: 0px;
        align-items: baseline;
    }

    .display-image-thumbnail-container {
        height: 200px;
        width: 250px;
        margin-right: 20px;
        margin-top: 20px;
    }

    .product-details h2 {
        margin-bottom: 30px;
        font-size: 15px;
    }

    .icon-and-text p {
        margin-bottom: 0px;
        font-size: 10px;
    }

    .icon-and-text h3 {
        margin-bottom: 0px;
        font-size: 13px;
    }
    
    .product-display-item {
        padding: 10px;
    }

    .icon-and-text {
        padding: 5px;
    }
    
    .icon-and-text .icon {
        margin-bottom: 0px;
    }
    
    .separator {
        margin: 5px 0;
    }
}

@media (max-width: 560px) {
    .product-details h2 {
        margin-bottom: 15px;
    }

}

@media (max-width: 480px) {
    .product-display-item {
        flex-direction: column;
    }

    .h2-displayed-below-480 {
        display: block;
        font-size: 14px;
        text-align: center;
        color: var(--color-primary);
    }

    .display-image-thumbnail-container {
        height: 250px;
        width: 300px;
        margin-bottom: 10px;
    }

    .h2-hidden-below-480 {
        display: none;
    }

    .specific-information {
        grid-template-columns: repeat(3, 1fr);
    }

    .icon-and-text h3 {
        font-size: 11px;
    }

    .icon-and-text .icon {
        width: 18px;
        height: 18px;
    }

    .icon-and-text p {
        font-size: 8px;
    }

    .descriptive-product-text {
        font-size: 8px;
    }
}

