.all-products {
    display: flex;
    width: 75%;
    margin: 0 auto;
    padding: 20px 10px;
    background-color: var(--color-bg-light);
    color: var(--color-text-grey);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.filters {
    flex: 1;
    padding: 20px;
    border-right: 1px solid #ccc;
    transition: max-height 0.3s ease-in-out;
}

.search-bar {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    margin-top: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-icon {
    margin-right: 10px;
    color: var(--color-secondary);
}

.search-bar input {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 4px;
    outline: none;
    font-size: 16px;
}

.search-bar input::placeholder {
    color: #aaa;
}

.filter-section {
    margin-top: 15px;
    color: var(--color-primary);
}

.filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.filters h3 {
    color: var(--color-primary);
}

hr {
    margin: 10px 0;
    border: none;
    border-top: 1px solid #ccc;
}

.all-products-product-list {
    flex: 4;
    display: flex;
    flex-direction: column;
    padding: 20px;
    min-height: 100vh;
}

.all-products-product-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px 50px;
    margin: 10px 0; /* Adjust vertical margin for spacing between items */
    width: 100%; /* Full width */
    transition: transform 0.3s ease-in-out; /* Smooth transition */
    background-color: white;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
}

.all-products-product-item:hover {
    transform: scale(1.01); /* Adjust the scale factor to control the size increase */
    color: inherit;
    text-decoration: none;
}

.all-products-image-thumbnail-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    width: 350px;
    margin-right: 40px;
    margin-top: 30px;
    overflow: hidden;
}

.all-products-image-thumbnail {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}

.all-products-product-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
}

.all-products-specific-information {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    align-items: baseline;
}

.all-products-product-details h2 {
    font-size: 25px;
    font-weight: 900;
    margin-bottom: 50px;
    color: var(--color-primary);
}

.all-products-icon-and-text h3 {
    font-size: 18px;
    margin-bottom: 5px;
    color: var(--color-primary-lighter);
}

.all-products-icon-and-text p {
    margin-bottom: 5px;
    font-size: 14px;
}

.icon-and-text {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 20px 0px;
}

.all-products-icon-and-text .icon {
    max-width: 35px;
    margin-right: 0;
    margin-bottom: 5px;
}

.dimmed {
    color: var(--color-text-grey);
    font-size: 14px;
    margin-bottom: 30px;
}

input[type='text'],
input[type='checkbox'] {
    margin-top: 5px;
}

/* Basic checkbox styling */
input[type='checkbox'] {
    appearance: none; /* Remove default checkbox styling */
    flex-shrink: 0;
    width: 15px;
    height: 15px;
    border: 1px solid var(--color-text-grey);
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    margin-right: 6px;
}

input[type='checkbox']:checked {
    background-color: var(--color-secondary);
}

input[type='checkbox']:checked::before {
    content: '\2713'; /* Unicode for checkmark */
    color: white;
    font-size: 10px;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

label {
    line-height: 20px; /* Match this to the checkbox height */
    vertical-align: 5%; /* Align label text vertically */
}

/* Collapsible and icon animation */
.collapsible {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-out;
}

.collapsible.expanded {
    max-height: 500px; /* Adjust based on content */
    transition: max-height 0.5s ease-in;
}

.icon {
    transition: transform 0.3s ease-out;
}

.icon.open {
    transform: rotate(-90deg);
}

.all-products-descriptive-product-text {
    font-size: 13px;
    text-align: left;
}

.show-filters-toggle-button {
    display: none;
    width: 100%;
    padding: 15px 0;
    font-size: 12px;
    background-color: var(--color-primary);
    color: white;
    font-weight: bold;
    text-align: center;
    border: none;
    cursor: pointer;
    position: fixed;
    border-radius: 0;
    top: 49px;
    left: 0;
    right: 0;
    z-index: 500;
}

.flex-column-h2 {
    display: none;
}

@media (max-width: 1700px) {
    .all-products {
        width: 100%;
    }
}

@media (max-width: 1250px) {
    .all-products {
        padding: 20px 0px;
    }

    .filters {
        padding: 20px;
    }

    .all-products-product-item {
        padding: 10px 20px;
    }

    .all-products-image-thumbnail-container {
        height: 200px;
        width: 300px;
        margin-right: 20px;
        margin-bottom: 10px;
    }

    .all-products-product-details h2 {
        font-size: 18px;
        margin-bottom: 40px;
    }

    .all-products-icon-and-text h3 {
        font-size: 13px;
    }

    .all-products-icon-and-text p {
        font-size: 12px;
    }

    .all-products-descriptive-product-text {
        font-size: 12px;
    }

    .all-products-icon-and-text .icon {
        max-width: 25px; /* Adjust size as needed */
        margin-right: 0;
        margin-bottom: 5px; /* Space between icon and text */
    }
}

@media (max-width: 1000px) {
    .all-products-specific-information {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }

    .all-products-image-thumbnail-container {
        height: 150px;
        width: 200px;
        margin-right: 20px;
        margin-bottom: 10px;
    }

    .all-products-icon-and-text h3 {
        font-size: 12px;
        margin-bottom: 0px;
    }

    .all-products-icon-and-text p {
        font-size: 10px;
        margin-bottom: 0px;
    }

    .all-products-descriptive-product-text {
        font-size: 10px;
    }

    .all-products-product-details h2 {
        font-size: 18px;
        margin-bottom: 30px;
    }

    .all-products-icon-and-text .icon {
        max-width: 20px;
        margin-bottom: 0px;
    }
}

@media (max-width: 800px) {

    .show-filters-toggle-button {
        display: block;
    }

    .all-products-product-list {
        padding: 10px;
    }

    .all-products-image-thumbnail-container {
        width: 300px; /* Make image container full width */
        height: 250px; /* Adjust the height if needed */
        margin-right: 15px;
        margin-bottom: 10px;
    }

    .all-products-image-thumbnail {
        width: 100%;
        height: auto; /* Maintain aspect ratio */
    }

    .filters {
        position: fixed;
        top: 65px;
        left: 0;
        right: 0;
        max-height: 0;
        overflow: hidden;
        background-color: var(--color-bg-light);
        transition: max-height 0.3s ease-in-out;
        z-index: 499;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        padding: 0 15px;
        margin: 0; /* Remove margin to prevent visibility */
    }

    .filters.visible {
        max-height: 100vh; /* Allow more space for the filters */
    }

    .filters h3 {
        font-size: 2px;
    }

    .dimmed {
        font-size: 2px;
    }

    .filters h4 {
        font-size: 12px;
    }

    .search-icon {
        margin-right: 10px;
        font-size: 14px;
    }

    .search-bar {
        padding: 0px;
        margin-top: 5px;
    }

    .search-bar input {
        flex: 1;
        padding: 7px;
        border: none;
        border-radius: 4px;
        outline: none;
        font-size: 12px;
    }

    .collapsible {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 3px;
    }

    .check-boxes-div {
        margin-top: 5px;
        display: flex;
        align-items: center;
        flex-basis: calc(30% - 5px);
        box-sizing: border-box;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .check-boxes-div label {
        font-size: 10px;
        line-height: 0px;
        vertical-align: 0%;
    }

    .check-boxes-div input[type='checkbox'] {
        width: 12px;
        height: 12px;
        border: 0.5px solid var(--color-text-grey);
        margin-right: 5px;
    }

    .check-boxes-div input[type='text'],
    .check-boxes-div input[type='checkbox'] {
        margin-top: 0px;
    }

    .check-boxes-div input[type='checkbox']:checked::before {
        font-size: 8px;
    }
    
    .all-products-product-details h2 {
        font-size: 16px;
    }
}

@media (max-width: 600px) {
    .check-boxes-div {
        flex-basis: calc(50% - 5px);
    }

    .all-products-image-thumbnail-container {
        width: 200px; /* Make image container full width */
        height: 150px; /* Adjust the height if needed */
        margin-right: 15px;
        margin-bottom: 10px;
    }

    .all-products-image-thumbnail {
        width: 100%;
        height: auto; /* Maintain aspect ratio */
    }

    .all-products-product-item {
        padding: 10px;
    }

    .all-products-product-details h2 {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .all-products-icon-and-text h3 {
        font-size: 11px;
    }

    .all-products-icon-and-text p {
        font-size: 9px;
    }

    .all-products-descriptive-product-text {
        font-size: 9px;
    }

    .all-products-icon-and-text .icon {
        max-width: 15px;
        margin-bottom: 2px;
    }
}

@media (max-width: 480px) {

   .all-products-product-item {
        flex-direction: column;
   }

   .flex-column-h2 {
        display: block;
        font-size: 14px;
        font-weight: 900;
        color: var(--color-primary);
    }

    .standard-h2-display {
        display: none;
    }

}