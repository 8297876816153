.brain-info-grid-container {
    padding: 70px 50px;
    background-color: var(--color-bg-light);
}

.brain-info-grid-container h1 {
    font-size: 2em;
    font-weight: 1000;
    margin-left: 100px;
    color: var(--color-primary);
    margin-bottom: 20px;
}

.brain-info-grid-container p {
    font-size: 1em;
    color: var(--color-primary);
    line-height: 1.6;
    margin-bottom: 20px;
    margin-left: 100px;
}

.brain-info-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.brain-info-item {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    width: calc(25% - 20px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    box-sizing: border-box;
    overflow: hidden;
}

.brain-info-item img {
    width: 100%;
    height: 180px;
    object-fit: cover;
    object-position: top;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--color-primary);
}

.brain-info-item h2 {
    font-size: 1.1em;
    font-weight: 800;
    margin-left: 15px;
    color: var(--color-primary);
}

.brain-info-item p {
    margin: 15px;
    font-size: 0.8em;
    color: var(--color-primary-lighter);
    line-height: 1.4;
}

@media (max-width: 1200px) {
    .brain-info-grid-container {
        padding: 70px 50px;
    }
    
    .brain-info-grid-container h1 {
        font-size: 1.2em;
        margin-bottom: 20px;
        margin-left: 0;
    }
    
    .brain-info-grid-container p {
        font-size: 0.8em;
        margin-left: 0;
    }

    .brain-info-grid p {
        margin: 5px 15px 15px 15px;
        font-size: 0.7em;
    }

    .brain-info-grid h2 {
        font-size: 1em;
    }

    .brain-info-grid img {
        height: 150px;
        margin-bottom: 5px;
    }

}
@media (max-width: 1000px) {
    .brain-info-item {
        width: calc(30% - 20px);
        flex: 1 1 calc(30% - 20px);
    }
}

@media (max-width: 700px) {
    .brain-info-item {
        width: calc(50% - 20px);
        flex: 1 1 calc(50% - 20px);
    }
}


@media (max-width: 480px) {
    .brain-info-item {
        width: 100%;
        flex: 1 1 100%;
    }

    .brain-info-grid-container {
        padding: 40px 20px;
    }
}
