.our-goals-container {
    padding: 50px 500px;
    background-color: var(--color-primary);
    color: var(--color-primary-light);
}

.our-goals-container h1 {
    color: white;
    font-weight: 800;
    margin-bottom: 25px;
}

.our-goals-container h2 {
    margin-bottom: 40px;
    font-weight: 300;
    font-size: 23px;
}

.our-goals-container ul {
    list-style: none;
    padding: 0;
    margin-bottom: 40px;
}

.our-goals-container ul li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.our-goals-container .checkmark {
    margin-right: 10px;
    color: var(--color-secondary);
}

@media (max-width: 1600px) {

  .our-goals-container {
    padding: 50px 300px; /* Adjust padding for large screens */
  }

}

@media (max-width: 1200px) {
    .our-goals-container {
      padding: 50px 200px; /* Adjust padding for medium screens */
    }
  
    .our-goals-container h1 {
      font-size: 28px; /* Adjust font size */
    }
  
    .our-goals-container h2 {
      font-size: 20px; /* Adjust font size */
      margin-bottom: 30px; /* Adjust margin */
    }
  
    .our-goals-container ul li {
      font-size: 18px; /* Adjust font size for list items */
    }
  
    .our-goals-container p {
      font-size: 16px; /* Adjust font size for paragraph */
    }
  }
  
  @media (max-width: 768px) {
    .our-goals-container {
      padding: 30px 100px; /* Adjust padding for small screens */
    }
  
    .our-goals-container h1 {
      font-size: 24px; /* Adjust font size */
    }
  
    .our-goals-container h2 {
      font-size: 18px; /* Adjust font size */
      margin-bottom: 20px; /* Adjust margin */
    }
  
    .our-goals-container ul li {
      font-size: 16px; /* Adjust font size for list items */
    }
  
    .our-goals-container p {
      font-size: 14px; /* Adjust font size for paragraph */
    }
  }
  
  @media (max-width: 480px) {
    .our-goals-container {
      padding: 20px 50px; /* Adjust padding for very small screens */
    }
  
    .our-goals-container h1 {
      font-size: 20px; /* Adjust font size */
    }
  
    .our-goals-container h2 {
      font-size: 16px; /* Adjust font size */
      margin-bottom: 15px; /* Adjust margin */
    }
  
    .our-goals-container ul li {
      font-size: 14px; /* Adjust font size for list items */
    }
  
    .our-goals-container p {
      font-size: 12px; /* Adjust font size for paragraph */
    }
  }
  