/* Container styling */
.whowe-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

/* Employee card styling */
.employee-card {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Employee name styling */
.employee-card h2 {
    margin-top: 0;
    color: var(--color-primary);
}

/* Employee role styling */
.employee-card p {
    margin: 10px 0;
    color: var(--color-text-grey);
    display: flex;
    align-items: center;
}

/* Icon styling */
.employee-card p svg {
    margin-right: 8px;
    color: var(--color-primary);
}

@media (max-width: 1200px) {
    .whowe-container {
      padding: 20px 40px; /* Adjust padding for medium screens */
    }
  
    .employee-card {
      padding: 15px; /* Adjust padding for medium screens */
      margin-bottom: 15px; /* Adjust margin for medium screens */
    }
  
    .employee-card h2 {
      font-size: 20px; /* Adjust font size for medium screens */
    }
  
    .employee-card p {
      font-size: 14px; /* Adjust font size for medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .whowe-container {
      padding: 20px 20px; /* Adjust padding for small screens */
    }
  
    .employee-card {
      padding: 10px; /* Adjust padding for small screens */
      margin-bottom: 10px; /* Adjust margin for small screens */
    }
  
    .employee-card h2 {
      font-size: 18px; /* Adjust font size for small screens */
    }
  
    .employee-card p {
      font-size: 12px; /* Adjust font size for small screens */
    }
  }
  
  @media (max-width: 480px) {
    .whowe-container {
      padding: 0px; /* Adjust padding for very small screens */
    }
  
    .employee-card {
      padding: 8px; /* Adjust padding for very small screens */
    }
  
    .employee-card h2 {
      font-size: 16px; /* Adjust font size for very small screens */
    }
  
    .employee-card p {
      font-size: 10px; /* Adjust font size for very small screens */
      margin: 5px 0; /* Adjust margin for very small screens */
    }
  
    .employee-card p svg {
      margin-right: 5px; /* Adjust icon margin for very small screens */
    }
  }
  