.youtube-embed-container {
    display: flex;
    align-items: center;
    padding: 50px 150px;
    background: var(--color-primary);
    color: var(--color-bg-light);
    gap: 70px;
    flex-wrap: wrap;
}

.youtube-embed-wrapper {
    flex: 1;
}

.youtube-embed-wrapper iframe {
    border-radius: 10px;
    width: 448px;
    height: 252px;
}
  
.youtube-embed-description {
    flex: 1;
}

.youtube-embed-description h2 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
}

.youtube-embed-description p {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 20px;
    color: var(--color-primary-light);
}

.youtube-embed-description li {
    font-size: 14px;
    margin-bottom: 10px;
    color: var(--color-primary-light);
    text-align: left; /* Ensure the text is left-aligned */
    list-style-position: inside; /* Ensure the number is inside the padding */
}

@media (max-width: 1650px) {
    .youtube-embed-container {
        padding: 50px;
        gap: 50px;
    }
}

@media (max-width: 768px) {
    .youtube-embed-container {
        flex-direction: column;
        gap: 20px;
    }

    .youtube-embed-description {
        text-align: center;
    }

    .youtube-embed-description h2 {
        font-size: 20px;
    }

    .youtube-embed-description li {
        font-size: 12px;
        text-align: center;
    }
}

@media (max-width: 420px) {
    .youtube-embed-container {
        padding: 20px;
        gap: 25px;
    }
}