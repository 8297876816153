.home-product-presentation {
    background-color: white;
    color: var(--color-primary);
    padding: 50px 200px;
}

.home-product-presentation-text {
    padding: 0 200px;
}

.home-product-presentation h1 {
    font-size: 25px;
    margin-bottom: 20px;
}

.home-product-presentation p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
}

.product-link-home {
    display: inline-flex;
    align-items: center;
    margin-bottom: 20px;
    color: var(--color-secondary-dark);
    font-size: 14px;
    text-decoration: none;
    transition: all 0.2s ease;
}

.product-link-home svg {
    font-size: 11px;
    vertical-align: middle;
    margin-left: 5px;
    transition: margin-left 0.2s ease;
}

.product-link-home:hover {
    text-decoration: underline;
    color: var(--color-secondary);
}

.product-link-home:hover svg {
    margin-left: 15px;
}

.home-product-grid {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
}

.home-product-card {
    width: 30%;
    border-radius: 10px;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin-bottom: 50px;
}

.home-product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.home-product-card img {
    width: 100%;
    border-radius: 10px 10px 0 0;
    height: auto;
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
}

.home-product-card h2 {
    font-size: 18px;
    margin: 10px 20px;
}

.home-product-card p {
    font-size: 14px;
    margin: 10px 20px;
}

.home-product-link {
    display: inline-block;
    margin: 10px 20px;
    margin-bottom: 30px;
    text-decoration: none;
    color: var(--color-primary);
    font-size: 14px;
    border: 1px solid var(--color-primary);
    padding: 5px 15px;
    border-radius: 6px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.home-product-link:hover {
    background-color: var(--color-primary);
    color: white;
}

@media (max-width: 1200px) {
    .home-product-presentation {
      padding: 25px 50px; /* Adjust padding for medium screens */
    }

    .home-product-grid {
      gap: 5px; /* Adjust gap for medium screens */
    }
  
    .home-product-presentation-text {
      padding: 0 100px; /* Adjust padding for medium screens */
    }
  
    .home-product-presentation h1 {
      font-size: 22px; /* Adjust font size */
      margin-bottom: 18px; /* Adjust margin */
    }
  
    .home-product-presentation p {
      font-size: 13px; /* Adjust font size */
      line-height: 18px; /* Adjust line height */
      margin-bottom: 18px; /* Adjust margin */
    }
  
    .product-link-home {
      font-size: 13px; /* Adjust font size */
    }
  
    .home-product-card h2 {
      font-size: 16px; /* Adjust font size */
    }
  
    .home-product-card p {
      font-size: 13px; /* Adjust font size */
    }
  
    .home-product-link {
      font-size: 13px; /* Adjust font size */
      padding: 4px 12px; /* Adjust padding */
    }
  }
  
  @media (max-width: 768px) {
    .home-product-presentation {
      padding: 0; /* Adjust padding for small screens */
    }
  
    .home-product-presentation-text {
      padding: 0 50px; /* Adjust padding for small screens */
    }
  
    .home-product-presentation h1 {
      font-size: 16px; /* Adjust font size */
      margin-bottom: 15px; /* Adjust margin */
    }
  
    .home-product-presentation p {
      font-size: 11px; /* Adjust font size */
      line-height: 14px; /* Adjust line height */
      margin-bottom: 12px; /* Adjust margin */
    }
  
    .product-link-home {
      font-size: 10px; /* Adjust font size */
      margin-bottom: 5px;
    }

    .product-link-home svg {
      font-size: 8px; /* Adjust font size */
    }
  
    .home-product-grid {
      gap: 0px; /* Adjust gap for small screens */
      justify-content: space-evenly; /* Center cards */
    }
  
    .home-product-card {
      width: 42%; /* Adjust card width for small screens */
    }

    .home-product-card img {
      margin-bottom: 0px; /* Adjust margin */
    }
  
    .home-product-card h2 {
      margin: 10px;
      font-size: 15px; /* Adjust font size */
    }
  
    .home-product-card p {
      margin: 10px;
      font-size: 11px; /* Adjust font size */
    }
  
    .home-product-link {
      margin: 10px;
      margin-bottom: 20px;
      font-size: 11px; /* Adjust font size */
      padding: 3px 10px; /* Adjust padding */
    }
  }
  
  @media (max-width: 480px) {
    .home-product-presentation {
      padding: 10px 30px; /* Adjust padding for very small screens */
    }
  
    .home-product-presentation-text {
      padding: 0; /* Adjust padding for very small screens */
    }
  
    .home-product-presentation h1 {
      font-size: 18px; /* Adjust font size */
      margin-bottom: 10px; /* Adjust margin */
    }
  
    .home-product-presentation p {
      font-size: 11px; /* Adjust font size */
      line-height: 14px; /* Adjust line height */
      margin-bottom: 10px; /* Adjust margin */
    }
  
    .product-link-home {
      font-size: 11px; /* Adjust font size */
    }
  
    .home-product-grid {
      gap: 5px; /* Adjust gap for very small screens */
    }
  
    .home-product-card {
      margin-bottom: 20px;
      width: 100%; /* Adjust card width for very small screens */
    }
  
    .home-product-card h2 {
      font-size: 14px; /* Adjust font size */
    }
  
    .home-product-card p {
      font-size: 11px; /* Adjust font size */
    }
  
    .home-product-link {
      font-size: 11px; /* Adjust font size */
      padding: 2px 8px; /* Adjust padding */
    }
  }
  