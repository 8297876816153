.brain-info-container {
    padding: 50px 150px;
    background: white;
    color: var(--color-primary);
}

.brain-info-container h1 {
    font-size: 2em;
    font-weight: 1000;
    margin-bottom: 40px;
    text-align: center;
}

.brain-info-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    gap: 50px;
    margin-bottom: 40px;
}

.brain-info-row p {
    margin: 0;
    flex-basis: 50%;
}

.brain-info-container p {
    font-size: 0.9em;
    line-height: 1.5;
    margin-bottom: 15px;
    color: var(--color-text-grey);
}

.brain-centered-text-bigger {
    text-align: center;
    color: var(--color-text-grey);
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 50px;
    margin-top: -15px;
}

.brain-info-components {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
}

.brain-info-component {
    flex: 1 1 calc(50% - 20px);
    border-radius: 8px;
    box-sizing: border-box;
}

.brain-info-component img {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    border-radius: 10px;
}

.brain-info-component h2 {
    font-size: 1.2em;
    font-weight: 1000;
    margin-bottom: 10px;
}

.brain-info-component ul {
    list-style-type: none;
    color: var(--color-text-grey);
    font-size: 14px;
    padding: 0;
}

.brain-info-component ul li {
    padding-left: 20px;
    margin-bottom: 5px;
}

.brain-info-component ul li svg {
    margin-right: 5px;
    color: var(--color-secondary);
}

.brain-info-component a {
    display: block;
    margin-top: 30px;
    font-size: 12px;
    color: var(--color-secondary);
    text-decoration: none;
}

.brain-info-component a svg {
    vertical-align: middle;
}

.brain-info-component a:hover {
    text-decoration: underline;
}

@media (max-width: 1200px) {
    .brain-info-container {
        padding: 40px 80px; /* Reduce padding for medium screens */
    }

    .brain-info-container h1 {
        font-size: 1.2em;
        margin-bottom: 10px;
    }

    .brain-info-container h4 {
        font-size: 0.9em;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: start;
    }

    .brain-info-row p {
        margin-bottom: 0px;
    }

    .brain-info-row {
        flex-direction: column;
        gap: 0px;
    }

    .brain-info-components {
        gap: 10px; /* Reduce gap between components */
    }

    .brain-info-component {
        flex: 1 1 100%; /* Full width for each component */
        padding: 10px; /* Add padding for better spacing */
    }

    .brain-info-component h2 {
        font-size: 1.1em; /* Adjust font size for headers */
    }

    .brain-info-component ul {
        font-size: 13px; /* Adjust font size for list items */
    }

    .brain-info-component a {
        font-size: 11px; /* Adjust font size for links */
    }

    .brain-centered-text-bigger {
        font-size: 16px; /* Reduce font size for medium screens */
    }
}

@media (max-width: 768px) {
    .brain-info-container {
        padding: 20px; /* Further reduce padding for tablet-sized screens */
    }

    .brain-info-row {
        gap: 20px; /* Reduce gap for more compact layout */
    }

    .brain-info-components {
        flex-direction: column; /* Stack components vertically */
        gap: 20px; /* Reduce gap between components */
    }

    .brain-info-component {
        flex: 1 1 100%; /* Ensure components are full-width */
        padding: 10px; /* Add padding for better spacing */
    }

    .brain-info-component h2 {
        font-size: 0.9em; /* Reduce font size for headers */
    }

    .brain-info-component ul {
        font-size: 11px; /* Smaller font size for list items */
    }

    .brain-info-component a {
        font-size: 10px; /* Smaller font size for links */
    }

    .brain-info-container h4 {
        font-size: 0.8em;
    }

    .brain-info-row p {
        font-size: 0.8em;
    }

    .brain-info-component p {
        font-size: 0.8em;
    }
}

@media (max-width: 480px) {
    .brain-info-container {
        padding: 15px; /* Minimal padding for mobile screens */
    }

    .brain-info-row {
        gap: 15px; /* Compact gap for mobile layout */
    }

    .brain-info-component {
        padding: 5px; /* Minimal padding for mobile */
    }

    .brain-info-component h2 {
        font-size: 0.9em; /* Reduce header size for mobile */
    }

    .brain-info-component ul {
        font-size: 11px; /* Smallest font size for list items */
    }

    .brain-info-component a {
        font-size: 9px; /* Smallest font size for links */
    }

    .brain-centered-text-bigger {
        font-size: 12px; /* Smallest font size for centered text */
    }
}
