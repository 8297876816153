.about {
    width: 65%;
    margin: 0 auto;
    padding: 20px 100px;
    background-color: var(--color-bg-light);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1440px) {
    .about {
        width: 80%;
    }
}

@media (max-width: 1024px) {
    .about {
        width: 100%;
        padding: 20px;
    }
}