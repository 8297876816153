/* Header.css */
.slide-container {
  position: relative;
  background-color: white;
}

.each-slide-effect {
  position: relative;
  width: 100%;
  height: 500px; /* Adjust as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

.each-slide-bg-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: background-image 1s ease-in-out;
}

.home-slider-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(45, 56, 63, 0.6);
}

.home-slider-text-span {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 170px;
  width: 550px;
  bottom: 0px;
  left: 100px;
  color: white;
  border-radius: 5px;
  gap: 10px;
}

.home-slider-cta-button {
  width: 50%;
  padding: 10px 20px;
  background-color: var(--color-secondary-dark);
  color: white;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.home-slider-cta-button:hover {
  background-color: var(--color-secondary);
}

.each-slide-bg-image span {
  font-size: 24px;
  font-weight: 700;
}

.custom-arrow {
  position: absolute;
  top: 50%;
  border-radius: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  line-height: 20px; /* Center the arrow vertically */
  text-align: center; /* Center the arrow horizontally */
  z-index: 2;
  font-size: 20px;
  line-height: 1;
}

.custom-arrow:hover{
  background-color: rgba(255, 255, 255, 0.3);
}

.custom-prev {
  margin-left: 10px;
}

.custom-next {
  margin-right: 10px;
}

.indicator {
  cursor: pointer;
  font-size: 40px;
  color: #ccc;
  transition: color 0.3s ease;
}

.indicator.active {
  color: #333;
}


@media (max-width: 1200px) {
  .each-slide-effect {
    height: 400px; /* Adjust as needed for medium screens */
  }

  .home-slider-text-span {
    width: 450px;
    bottom: 50px;
    left: 75px;
    height: auto;
    gap: 8px;
  }

  .home-slider-cta-button {
    width: 60%;
    font-size: 12px;
    padding: 8px 16px;
  }

  .each-slide-bg-image span {
    font-size: 20px;
  }

  .custom-arrow {
    width: 35px;
    height: 35px;
    line-height: 12px;
    font-size: 17.5px;
  }

  .indicator {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .each-slide-effect {
    height: 300px; /* Adjust as needed for small screens */
  }

  .home-slider-text-span {
    width: 350px;
    bottom: 30px;
    left: 60px;
    height: auto;
    gap: 6px;
  }

  .home-slider-cta-button {
    width: 70%;
    font-size: 10px;
    padding: 6px 12px;
  }

  .each-slide-bg-image span {
    font-size: 18px;
  }

  .custom-arrow {
    width: 30px;
    height: 30px;
    line-height: 10px;
    font-size: 16px;
  }

  .indicator {
    font-size: 25px;
  }
}

@media (max-width: 480px) {
  .each-slide-effect {
    height: 200px; /* Adjust as needed for very small screens */
  }

  .home-slider-text-span {
    width: 250px;
    bottom: 20px;
    left: 40px;
    height: auto;
    gap: 4px;
  }

  .home-slider-cta-button {
    width: 45%;
    font-size: 8px;
    padding: 4px 8px;
  }

  .each-slide-bg-image span {
    font-size: 11px;
  }

  .custom-prev {
    margin-left: 5px;
  }

  .custom-next {
    margin-right: 5px;
  }

  .custom-arrow {
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 9px;
    padding: 0px;
  }

  .indicator {
    font-size: 20px;
  }
}
