.news-homepage {
    padding: 50px 300px;
    background-color: white;
    color: var(--color-primary);
}

.news-homepage h2 {
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 20px;
}

.news-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 50px;
    margin-bottom: 50px;
}

.news-article {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
    background-color: var(--color-bg-light);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.news-article img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: center;
    border-bottom: 1px solid #e0e0e0;
}

.news-article h3 {
    font-size: 16px;
    font-weight: 800;
    margin: 10px 20px;
}

.news-article .date {
    font-size: 12px;
    color: #999;
    margin: 10px 20px;
}

.news-article p {
    font-size: 14px;
    color: #333;
    margin: 10px 20px;
}

.news-article .read-more {
    margin: 10px 20px;
    font-size: 14px;
    color: var(--color-secondary-dark);
    text-decoration: none;
    display: inline-flex;
    align-items: center;
}

.news-article svg {
    font-size: 11px;
    vertical-align: middle;
    margin-left: 5px;
    transition: margin-left 0.2s ease;
}

.news-article .read-more:hover {
    text-decoration: underline;
    color: var(--color-secondary);
}

.read-more:hover svg {
    margin-left: 15px;
}

@media (max-width: 1600px) {

  .news-homepage {
    padding: 50px 100px; /* Adjust padding for medium screens */
  }
}

@media (max-width: 1200px) {
    .news-grid {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 20px;
    }

    .news-homepage {
      padding: 50px 100px; /* Adjust padding for medium screens */
    }
  
    .news-homepage h2 {
      font-size: 22px; /* Adjust font size */
      margin-bottom: 8px; /* Adjust margin */
      margin-top: 18px; /* Adjust margin */
    }

    .news-article img {
      height: 200px; /* Adjust image height for medium screens */
    }
  
    .news-article h3 {
      font-size: 15px; /* Adjust font size */
      margin: 8px 15px; /* Adjust margin */
    }
  
    .news-article .date {
      font-size: 11px; /* Adjust font size */
      margin: 8px 15px; /* Adjust margin */
    }
  
    .news-article p {
      font-size: 13px; /* Adjust font size */
      margin: 8px 15px; /* Adjust margin */
    }
  
    .news-article .read-more {
      font-size: 13px; /* Adjust font size */
      margin: 8px 15px; /* Adjust margin */
    }
  }

  @media (max-width: 995px) {
    .news-homepage {
        padding: 25px 50px; /* Adjust padding for medium screens */
      }

    .news-grid {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 20px;
    }
  }
  
  @media (max-width: 768px) {
    .news-homepage {
      padding: 15px 25px; /* Adjust padding for small screens */
    }

    .news-grid {
      gap: 10px; /* Adjust gap */
      margin-bottom: 20px;
    }
  
    .news-homepage h2 {
      font-size: 20px; /* Adjust font size */
      margin-bottom: 6px; /* Adjust margin */
      margin-top: 16px; /* Adjust margin */
    }
  
    .news-article h3 {
      font-size: 14px; /* Adjust font size */
      margin: 6px 10px; /* Adjust margin */
    }
  
    .news-article .date {
      font-size: 10px; /* Adjust font size */
      margin: 6px 10px; /* Adjust margin */
    }
  
    .news-article p {
      font-size: 12px; /* Adjust font size */
      margin: 6px 10px; /* Adjust margin */
    }
  
    .news-article .read-more {
      font-size: 12px; /* Adjust font size */
      margin: 6px 10px; /* Adjust margin */
    }
  
    .news-article img {
      height: 200px; /* Adjust image height for small screens */
    }
  }
  
  @media (max-width: 480px) {
    .news-homepage {
      padding: 20px 20px; /* Adjust padding for very small screens */
    }

    .news-grid {
      gap: 20px; /* Adjust gap */
      margin-bottom: 10px;
    }
  
    .news-homepage h2 {
      font-size: 18px; /* Adjust font size */
      margin-bottom: 4px; /* Adjust margin */
      margin-top: 14px; /* Adjust margin */
    }
  
    .news-article h3 {
      font-size: 13px; /* Adjust font size */
      margin: 4px 5px; /* Adjust margin */
    }
  
    .news-article .date {
      font-size: 9px; /* Adjust font size */
      margin: 4px 5px; /* Adjust margin */
    }
  
    .news-article p {
      font-size: 11px; /* Adjust font size */
      margin: 4px 5px; /* Adjust margin */
    }
  
    .news-article .read-more {
      font-size: 11px; /* Adjust font size */
      margin: 4px 5px; /* Adjust margin */
    }
  
    .news-article img {
      height: 150px; /* Adjust image height for very small screens */
    }
  }
  