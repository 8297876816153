.highlights-container {
    background-color: var(--color-bg-light);
    padding: 40px 150px;
    text-align: center;
}

.highlights-container h2 {
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 40px;
    color: var(--color-primary);
}

.highlights-content {
    display: flex;
    justify-content: space-around;
    text-align: left;
}

.left-highlights {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 50px;
}

.highlight-list {
    flex: 1;
}

.highlight {
    margin-bottom: 20px;
}

.highlight h3 {
    font-size: 16px;
    margin-bottom: 10px;
    color: var(--color-primary);
}

.highlight p, .highlight ul {
    font-size: 14px;
    color: var(--color-text-grey)
}

.highlight ul {
    list-style-type: none;
    padding: 0;
}

.highlight ul li {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
}

.highlight svg {
    color: var(--color-secondary);
    margin-right: 10px;
}

@media (max-width: 1200px) {
    .highlights-container {
        padding: 40px 100px;
    }

    .highlights-content {
        flex-direction: column;
        align-items: flex-start;
    }

    .left-highlights {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .highlight-list {
        margin-bottom: 20px;
    }
}

@media (max-width: 768px) {
    .highlights-container {
        padding: 30px 50px;
    }

    .highlight h3 {
        font-size: 14px;
    }

    .highlight p, .highlight ul {
        font-size: 12px;
    }
}

@media (max-width: 480px) {
    .highlights-container {
        padding: 20px;
    }

    .highlights-container h2 {
        font-size: 20px;
    }

    .highlight h3 {
        font-size: 12px;
    }

    .highlight p, .highlight ul {
        font-size: 10px;
    }
}