.service-header-text-container {
    text-align: center;
    padding: 50px 250px;
    background-color: #fff;
}

.service-header-text-container h2 {
    font-size: 32px;
    font-weight: 800;
    margin-bottom: 20px;
    color: var(--color-primary)
}

.service-header-text-container p {
    font-size: 18px;
    color: var(--color-text-grey);
    line-height: 1.5;
}

@media (max-width: 1200px) {
    .service-header-text-container {
        padding: 40px 150px; /* Reduce padding for medium screens */
    }

    .service-header-text-container h2 {
        font-size: 28px; /* Slightly reduce font size for headers */
    }

    .service-header-text-container p {
        font-size: 16px; /* Slightly reduce font size for paragraphs */
    }
}

@media (max-width: 768px) {
    .service-header-text-container {
        padding: 30px 50px; /* Further reduce padding for tablet-sized screens */
    }

    .service-header-text-container h2 {
        font-size: 24px; /* Adjust font size for smaller screens */
    }

    .service-header-text-container p {
        font-size: 14px; /* Further reduce font size for paragraphs */
    }
}

@media (max-width: 480px) {
    .service-header-text-container {
        padding: 20px; /* Minimal padding for mobile screens */
    }

    .service-header-text-container h2 {
        font-size: 20px; /* Further reduce font size for headers */
    }

    .service-header-text-container p {
        font-size: 12px; /* Smallest font size for paragraphs */
    }
}
