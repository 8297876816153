.training-contents h2 {
    font-size: 1.5rem;
    font-weight: 1000;
    color: var(--color-primary);
}

.training-contents h1 {
    color: var(--color-primary);
    font-weight: 300;
    font-size: 1.5rem;
    text-align: center;
    width: 100%;
}

.training-contents h3 {
    color: var(--color-text-grey);
    font-weight: 1000;
    font-size: 1rem;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.training-contents h4 {
    color: var(--color-primary);
    font-weight: 500;
    font-size: 1.1rem;
    margin-bottom: 20px;
}

.training-contents-container {
    padding: 30px 50px;
}

.training-contents-divider-horizontal {
    width: 100%;
    height: 1px;
    background-color: var(--color-text-grey);
    margin-bottom: 40px;
}

.training-contents-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}

.training-contents-header-logos {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}

.training-contents-logo {
    height: 50px;
}

.training-contents-header {
    display: flex;
    align-items: center;
}

.training-contents-divider {
    width: 1px;
    height: 50px; /* Adjust height as needed */
    background-color: #ccc; /* Adjust color as needed */
    margin: 0 15px; /* Adjust spacing as needed */
}


.training-contents-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    gap: 50px;
}

.training-contents-side-image {
    width: 30%;
}

.training-contents-main-video {
    background-color: var(--color-bg-light);
    padding: 70px 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;
}

.training-contents-main-video-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
    border-radius: 15px;
    overflow: hidden;
}

.training-contents-main-video-container iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.white-bg {
    background-color: white;
}

.training-contents-video-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
    margin-top: 40px;
    width: 100%;
}

.training-contents-grid-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.training-contents-help-section {
    background-color: var(--color-primary);
    padding: 75px 10px;
    display: flex;
    justify-content: center;
}

.training-contents-help-section h2 {
    color: white;
    font-size: 2rem;
}

.training-contents-help-steps {
    background-color: var(--color-bg-light);
    padding: 50px 100px;
}

.training-contents-help-steps h2 {
    margin-bottom: 10px;
}

.training-contents-help-steps li h2 {
    margin-bottom: 0;
}

.training-contents-help-steps li {
    font-size: 1.5rem;
    font-weight: 1000;
    margin-bottom: 30px;
    color: var(--color-primary);
}

.training-contents-help-steps p {
    margin-top: -20px;
    font-size: 1.1rem;
    font-weight: 400;
    color: var(--color-primary-lighter);
}

.training-contents-help-steps ol {
    list-style-type: none;
}

@media (max-width: 768px) {
    .training-contents-container {
        padding: 20px 30px;
    }
    
    .training-contents-help-steps {
        padding: 50px 75px;
    }

    .training-contents-main-video {
        padding: 70px 50px;
    }

    .training-contents-image-container {
        gap: 50px;
    }

    .training-contents-main-video {
        gap: 10px;
    }
    
    .training-contents-video-grid {
        gap: 20px;
    }

    .training-contents h2 {
        font-size: 1.3rem;
    }
    
    .training-contents h1 {
        font-size: 1.3rem;
    }
    
    .training-contents h3 {
        font-size: 0.8rem;
    }
    
    .training-contents h4 {
        font-size: 0.9rem;
    }

    .training-contents-help-steps li {
        font-size: 1.3rem;
    }
    
    .training-contents-help-steps p {
        font-size: 0.9rem;
        margin-top: -15px;
    }

}

@media (max-width: 425px) {

    .training-contents-logo {
        height: 30px;
    }    

    .training-contents-container {
        padding: 20px 10px;
    }
    
    .training-contents-help-steps {
        padding: 20px 30px;
    }

    .training-contents-main-video {
        padding: 30px 50px;
    }

    .training-contents-image-container {
        gap: 50px;
    }

    .training-contents-main-video {
        gap: 10px;
    }

    .training-contents h2 {
        font-size: 0.9rem;
    }
    
    .training-contents h1 {
        font-size: 0.9rem;
    }
    
    .training-contents h3 {
        font-size: 0.6rem;
    }
    
    .training-contents h4 {
        font-size: 0.7rem;
        margin-bottom: 10px;
    }

    .training-contents-help-section {
        padding: 50px 10px;
    }

    .training-contents-help-steps li {
        font-size: 0.9rem;
    }
    
    .training-contents-help-steps p {
        font-size: 0.6rem;
    }

    .training-contents-video-grid {
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
    }
}

@media (max-width: 320px) {
    .training-contents-main-video {
        padding: 30px 20px;
    }
}