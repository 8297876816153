.contact-container {
    width: 65%;
    margin: 0 auto;
    background-color: var(--color-bg-light);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    min-height: 100vh;
}

.contact-container h2 {
    font-size: 38px;
    font-weight: 800;
    margin-bottom: 30px;
    color: var(--color-primary);
}

.contact-container p {
    font-size: 20px;
    font-weight: 300;
    color: var(--color-primary);
    margin-bottom: 20px;
}

.contact-normal {
    text-align: center;
    padding: 50px;
    background-size: cover;
    background-position: center;
    position: relative;
    height: 400px; /* Ensure the height matches the aspect ratio of the image */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact-normal::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8); /* Adjust the transparency as needed */
    z-index: 0;
}

.contact-normal h2,
.contact-normal p {
    position: relative;
    z-index: 1;
}


.contact-info {
    background-color: var(--color-primary);
    color: var(--color-bg-light);
    padding: 50px;
    padding-top: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.main-phonenumber {
    display: flex;
    align-items: flex-end;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-right: 100px;
}

.main-phonenumber svg {
    color: var(--color-secondary);
    font-size: 80px;
    margin-right: 25px;
}

.main-phonenumber-text {
    display: flex;
    flex-direction: column;
}

.phone-number-thin {
    font-weight: 200;
}

.service-contact {
    margin-bottom: 20px;
}

.info-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
}

.info-item svg {
    color: var(--color-secondary);
    margin-right: 10px;
}

.contact-form-container {
    padding: 50px 200px;
    color: var(--color-primary);
}

.contact-form-container p {
    font-size: 18px;
    font-weight: 350;
    color: var(--color-primary-lighter);
}

.contact-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    padding: 10px 0px;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.form-group textarea {
    resize: vertical;
    height: 150px;
}

button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: var(--color-secondary-dark);
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 200px;
    margin-top: 20px;
}

button:hover {
    background-color: var(--color-secondary);
}

.confirmation-message {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 500px;
}

.confirmation-message h3 {
    margin-top: 20px;
    font-size: 24px;
    color: var(--color-secondary-dark);
}

.confirmation-message p {
    font-size: 18px;
    margin-top: 10px;
    color: #333;
}

.back-home-link {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: var(--color-secondary-dark);
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.back-home-link:hover {
    background-color: var(--color-secondary);
}

@media (max-width: 1440px) {
    .contact-container {
        width: 80%;
    }

    .button {
        width: 40%;
    }

    .contact-form-container {
        padding: 50px 100px;
    }

}

@media (max-width: 1024px) {
    .contact-container {
        width: 100%;
    }

    .main-phonenumber {
        margin-right: 50px;
        font-size: 20px;
    }

    .main-phonenumber svg {
        font-size: 60px;
        margin-right: 15px;
    }

    .contact-info {
        padding: 50px 20px;
        padding-top: 70px;
    }

    .info-item {
        font-size: 14px;
    }

}

@media (max-width: 768px) {
    .contact-form-container {
        padding: 50px;
    }
  }

@media (max-width: 590px) {
    .contact-info {
        flex-direction: column;
        padding: 40px 0px;
    }

    .main-phonenumber {
        margin-right: 0;
        font-size: 18px;
        margin-bottom: 40px;
    }
    
    .main-phonenumber svg {
        font-size: 50px;
        margin-right: 15px;
    }

    .info-item {
        font-size: 12px;
    }

    .contact-form-container {
        padding: 30px 20px;
    }

    .contact-form-container p {
        font-size: 14px;
    }

    .form-group label {
        font-size: 14px;
        margin-bottom: 0px;
    }

}
  