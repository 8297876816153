.service-quad-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 30px;
    padding: 50px 100px;
    background: var(--color-primary)
}

.quad-item {
    padding: 20px;
    color: var(--color-bg);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.quad-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.quad-image {
    background-size: cover;
    background-position: center;
    min-height: 300px;
    width: 100%;
    border-radius: 20px;
}
.quad-text h2 {
    font-size: 1.8em;
    margin-bottom: 20px;
}

.quad-text p {
    font-size: 1em;
    margin-bottom: 10px;
}

.quad-text ul {
    list-style: none;
    padding: 0;
    color: #fff;
}

.quad-text ul li {
    margin-bottom: 10px;
    font-size: 1em;
    color: #fff;
}

@media (max-width: 1200px) {
    .service-quad-section {
        padding: 40px 80px; /* Reduce padding for medium screens */
        gap: 20px; /* Adjust gap between grid items */
    }

    .quad-text h2 {
        font-size: 1.6em; /* Reduce font size for headers */
    }

    .quad-text p,
    .quad-text ul li {
        font-size: 0.9em; /* Adjust font size for paragraphs and list items */
    }

    .quad-image {
        min-height: 250px; /* Adjust image height */
    }
}

@media (max-width: 768px) {
    .service-quad-section {
        grid-template-columns: 1fr; /* Stack all items in a single column */
        padding: 30px 50px; /* Further reduce padding for smaller screens */
        gap: 15px; /* Reduce gap for a more compact layout */
    }

    .quad-image-1 {
        order: -1; /* Change order of first image */
    }

    .quad-item {
        min-height: 200px; /* Adjust minimum height for items */
    }

    .quad-text h2 {
        font-size: 1.4em; /* Further reduce font size for headers */
    }

    .quad-text p,
    .quad-text ul li {
        font-size: 0.85em; /* Smaller font size for text elements */
    }

    .quad-image {
        min-height: 200px; /* Further adjust image height for smaller screens */
    }
}

@media (max-width: 480px) {
    .service-quad-section {
        padding: 20px; /* Minimal padding for mobile devices */
        gap: 10px; /* Smallest gap for compact layout */
    }

    .quad-text h2 {
        font-size: 1.2em; /* Smaller font size for headers */
    }

    .quad-text p,
    .quad-text ul li {
        font-size: 0.8em; /* Smallest font size for text */
    }

    .quad-image {
        min-height: 150px; /* Minimized image height for mobile */
    }
}
