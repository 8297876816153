.footer {
    background-color: var(--color-primary);
    color: white;
    padding: 20px;
    display: flex;
    justify-content: space-between;
}

.footer-section {
    flex: 1;
    padding: 20px;
}

.footer-section h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin: 10px 0;
}

.footer-section ul li a {
    color: white;
    text-decoration: none;
}

.footer-section ul li a:hover {
    text-decoration: underline;
}

.footer-section p {
    margin: 10px 0;
    font-size: 1.1rem;
}

.social-media {
    margin-top: 20px;
}

.social-media a {
    color: white;
    font-size: 2rem;
    margin-right: 15px;
}

.social-media a:hover {
    color: var(--color-secondary);
}

@media (max-width: 1200px) {

    .footer-section {
        width: 100%;
        padding: 20px 0;
    }

    .footer-section h2 {
        font-size: 1.3rem;
    }

    .footer-section p, 
    .footer-section ul li a, 
    .footer-section ul li {
        font-size: 1rem;
    }

    .social-media a {
        font-size: 1.5rem;
    }
}

@media (max-width: 768px) {
    .footer {
        padding: 15px;
    }

    .footer-section {
        padding: 15px 0;
    }

    .footer-section h2 {
        font-size: 1.2rem;
    }

    .footer-section p, 
    .footer-section ul li a, 
    .footer-section ul li {
        font-size: 0.9rem;
    }

    .social-media a {
        font-size: 1.3rem;
    }
}

@media (max-width: 480px) {
    .footer {
        padding: 10px;
    }

    .footer-section {
        padding: 10px 0;
    }

    .footer-section h2 {
        font-size: 1rem;
    }

    .footer-section p, 
    .footer-section ul li a, 
    .footer-section ul li {
        font-size: 0.8rem;
    }

    .social-media a {
        font-size: 1rem;
    }
}

@media (max-width: 372px) {
    .footer {
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }
}