.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 200px;
    background-color: white;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    height: 70px;
}

.logo img {
    height: 50px;
}

.navbar {
    display: flex;
    align-items: center;
}

.nav-menu {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.nav-item {
    margin: 0 10px;
    position: relative;
}

.nav-item a {
    color: var(--color-primary);
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
    transition: color 0.3s;
    padding-bottom: 5px;
}

.nav-item a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -5px;
    left: 50%;
    background-color: var(--color-secondary);
    transition: width 0.3s ease, left 0.3s ease;
}

.nav-item a:hover::after,
.products-dropdown a:hover::after {
    width: 100%;
    left: 0;
}

/* Dropdown menu styling */
.products-dropdown .dropdown {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 1000;
}

.products-dropdown .dropdown li a::after {
    content: none;
}

.products-dropdown a {
    display: inline-flex;
    align-items: center;
}

.products-dropdown a svg {
    margin-left: 5px;
    vertical-align: middle;
    transition: transform 0.3s ease;
}

.arrow-icon.up {
    transform: rotate(180deg);
}

.products-dropdown .dropdown li {
    padding: 5px 20px;
}

.products-dropdown .dropdown li a {
    color: var(--color-primary);
    text-decoration: none;
    white-space: nowrap;
    display: block;
    padding: 5px 0;
}

.products-dropdown .dropdown li:hover {
    background-color: var(--color-secondary);
}

.products-dropdown .dropdown.show {
    margin-top: 21px;
    display: block;
    font-size: 15px;
    background-color: var(--color-bg-light);
}

.cta-button {
    margin-left: 20px;
    padding: 8px 17px;
    font-size: 14px;
    background-color: var(--color-primary);
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.cta-button:hover {
    background-color: var(--color-secondary);
}

.menu-toggle {
    display: none;
    font-size: 24px;
    cursor: pointer;
}

@media (max-width: 1200px) {
    .navbar-container {
        padding: 10px 40px;
    }

    .nav-item a {
        font-size: 12px;
    }
}

@media (max-width: 768px) {
    .navbar-container {
        padding: 10px 10px;
    }

    .nav-item a {
        font-size: 12px;
    }

    .cta-button {
        margin-bottom: 10px;
        margin-top: 10px;
        margin-left: 10px;
        padding: 6px 14px;
        font-size: 12px;
    }

    .navbar {
        display: none;
        flex-direction: column;
        align-items: flex-start; /* Align items to the left */
        width: 100%;
        position: absolute;
        top: 70px; /* Adjust to place it just below the navbar */
        right: 0;
        background-color: var(--color-bg-light); /* Set the background color */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        padding: 5px;
        z-index: 999; /* Ensure it is above other elements */
    }

    .navbar.expanded {
        display: flex;
    }

    .nav-menu {
        flex-direction: column;
        align-items: flex-start; /* Align items to the left */
        width: 100%;
    }

    .nav-item {
        margin: 5px 10px; /* Adjust spacing for better layout */
    }

    .menu-toggle {
        display: block;
    }

    .logo img {
        height: 40px;
    }
}

@media (max-width: 480px) {
    .navbar-container {
        padding: 10px;
    }

    .nav-item a {
        font-size: 10px;
    }

    .cta-button {
        padding: 5px 10px;
        font-size: 10px;
    }
}