.news-header-image {
    width: 100%;
    height: 500px;
}

.news-header-content {
    text-align: left;
    padding: 30px 50px;
    background-color: var(--color-bg-light);
}

.text-container {
    flex: 1;
    z-index: 2;
}

.news-header h1 {
    font-size: 2rem;
    margin-bottom: 10px;
}

.green-h1 {
    margin-left: 10px;
    color: var(--color-secondary);
}

.header-h1 {
    display: flex;
    align-items: center;
}

.news-header p {
    font-size: 1.3rem;
    font-weight: 200;
    margin-bottom: 40px;
}

@media (max-width: 1800px) {
    .news-header h1 {
        font-size: 1.8rem;
    }
}

@media (max-width: 1200px) {
    .text-container h1 {
        font-size: 1.2rem;
    }

    .text-container p {
        font-size: 1.0rem;
    }
}

@media (max-width: 768px) {
    .text-container h1 {
        font-size: 1.0rem;
    }

    .text-container p {
        font-size: 0.8rem;
        margin-bottom: 30px;
    }

    .news-header-content {
        padding: 15px 25px;
    }

    .news-header-image {
        height: 400px;
    }
    
    .green-h1 {
        margin-left: 5px;
    }

    
}

@media (max-width: 480px) {
    .text-container h1 {
        font-size: 1.0rem;
    }

    .text-container p {
        font-size: 0.8rem;
        margin-bottom: 25px;
    }
    .news-header-image {
        height: 300px;
    }
    
}