.product-loading {
  width: 65%;
  margin: 0 auto;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  min-height: 100vh;
}

.product-loading h1 {
    font-size: 2rem;
    font-weight: 500;
    color: var(--color-primary);
    padding: 500px;
}