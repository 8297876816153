.brain-webportal-container {
    padding: 50px 150px;
}

.brain-webportal-container h1 {
    font-size: 1.5em;
    font-weight: 1000;
    color: var(--color-primary);
    margin-bottom: 20px;
}

.brain-webportal-container p {
    font-size: 0.9em;
    color: var(--color-text-grey);
    line-height: 1.6;
    margin-bottom: 15px;
}

.brain-webportal-container ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
}

.brain-webportal-container ul li {
    padding-left: 25px;
    margin-bottom: 10px;
    font-size: 0.9em;
    color: var(--color-text-grey);
}

.brain-webportal-container ul li svg{
    color: var(--color-secondary);
    margin-right: 5px;
}

.brain-webportal-container a {
    display: block;
    font-size: 0.8em;
    margin-top: 20px;
    color: var(--color-secondary);
    text-decoration: none;
}

.brain-webportal-container a svg {
    vertical-align: center;
}

.brain-webportal-container a:hover {
    text-decoration: underline;
}

.brain-webportal-container img {
    width: 70%;
    height: auto;
    margin-top: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

@media (max-width: 1200px) {
    .brain-webportal-container {
        padding: 40px 100px; /* Reduce padding for medium screens */
    }

    .brain-webportal-container h1 {
        font-size: 1.2em; /* Slightly reduce font size */
    }

    .brain-webportal-container p,
    .brain-webportal-container ul li {
        font-size: 0.85em; /* Adjust font size for paragraphs and list items */
    }

    .brain-webportal-container img {
        width: 80%; /* Adjust image width for better fit */
    }
}

@media (max-width: 768px) {
    .brain-webportal-container {
        padding: 30px 50px; /* Further reduce padding for tablet-sized screens */
    }

    .brain-webportal-container h1 {
        font-size: 1.2em; /* Adjust font size for smaller screens */
    }

    .brain-webportal-container p,
    .brain-webportal-container ul li {
        font-size: 0.8em; /* Further reduce font size */
    }

    .brain-webportal-container img {
        width: 90%; /* Increase image width for smaller screens */
    }
}

@media (max-width: 480px) {
    .brain-webportal-container {
        padding: 20px; /* Minimal padding for mobile screens */
    }

    .brain-webportal-container h1 {
        font-size: 1em; /* Further reduce font size */
    }

    .brain-webportal-container p,
    .brain-webportal-container ul li {
        font-size: 0.75em; /* Smallest font size for readability */
    }

    .brain-webportal-container a {
        font-size: 0.7em; /* Adjust link font size */
    }

    .brain-webportal-container img {
        width: 100%; /* Full width for mobile screens */
    }
}
