.service-header-image {
    width: 100%;
    height: 400px;
    object-fit: cover;
    object-position: center;
}

@media (max-width: 768px) {
    .service-header-image {
        height: 300px;
    }
}

@media (max-width: 480px) {
    .service-header-image {
        height: 200px;
    }
}