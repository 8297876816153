.employees-header-text {
  padding: 60px 150px;
  margin: 40px -100px;
  color: var(--color-bg-light);
  background-color: var(--color-primary);
}

.employees-header-text h1 {
  font-size: 32px;
  font-weight: 800;
  margin-bottom: 20px;
}

.employees-header-text p {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
  color: var(--color-primary-light);
}

@media (max-width: 1200px) {
  .employees-header-text {
    padding: 50px 100px; /* Adjust padding for medium screens */
  }

  .employees-header-text h1 {
    font-size: 28px; /* Adjust font size for medium screens */
  }

  .employees-header-text p {
    font-size: 16px; /* Adjust font size for medium screens */
  }
}

@media (max-width: 1024px) {
  .employees-header-text {
    padding: 40px 80px; /* Adjust padding for small screens */
    margin: 0px -20px;
  }
}

@media (max-width: 768px) {
  .employees-header-text {
    padding: 40px 50px; /* Adjust padding for small screens */
  }

  .employees-header-text h1 {
    font-size: 24px; /* Adjust font size for small screens */
  }

  .employees-header-text p {
    font-size: 14px; /* Adjust font size for small screens */
  }
}

@media (max-width: 480px) {
  .employees-header-text {
    padding: 30px; /* Adjust padding for very small screens */
    margin: 20px -20px; /* Adjust margin for very small screens */
  }

  .employees-header-text h1 {
    font-size: 18px; /* Adjust font size for very small screens */
  }

  .employees-header-text p {
    font-size: 11px; /* Adjust font size for very small screens */
  }
}
