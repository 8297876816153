.brain {
    width: 65%;
    margin: 0 auto;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1440px) {
    .brain {
        width: 85%;
    }
}

@media (max-width: 1200px) {
    .brain {
        width: 100%;
    }
}