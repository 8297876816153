.spec-container {
    padding: 50px;
    color: var(--color-primary);
}

.spec-container h2 {
    font-size: 24px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 50px;
}

.spec-flexbox {
    display: flex;
    justify-content: space-between;
    gap: 50px;
}

.spec-content {
    flex: 1;
    font-size: 12px;
}

.spec-table {
    max-height: 350px; 
    overflow: hidden;
    transition: max-height 0.5s ease-out;
}

.spec-table.expanded {
    max-height: 1000px;
}

.spec-table table {
    width: 100%;
    border-collapse: collapse;
}

.spec-table td {
    border-top: 1px solid var(--color-bg);
    border-bottom: 1px solid var(--color-bg);
    padding: 8px;
}

.spec-table td:first-child {
    width: 320px;
    padding-right: 20px; 
}

.spec-image-and-brochure {
    flex: 1;
    justify-content: center;
    align-items: center;
}

.spec-image img {
    max-width: 100%;
    height: auto;
}

.download-brochure {
    display: block;
    text-align: center;
    margin-top: 20px;
    color: var(--color-secondary);
    text-decoration: none;
    font-size: 12px;
}

.download-brochure:hover {
    text-decoration: underline;
}

.toggle-details {
    cursor: pointer;
    color: var(--color-secondary);
    text-align: center;
    margin-top: 10px;
}

.toggle-details span {
    font-weight: bold;
}

.toggle-details:hover {
    text-decoration: underline;
}

@media (max-width: 1300px) {

}

@media (max-width: 768px) {
    .spec-container {
        padding: 25px;
    }
    
    .spec-container h2 {
        font-size: 20px;
        margin-bottom: 30px;
    }
    .spec-table td {
        padding: 6px;
        font-size: 10px;
    }

    .spec-flexbox {
        gap: 20px;
    }
}

@media (max-width: 420px) {

}